import React, { useEffect, useState } from "react";
import {
  ChevronsLeft,
  ChevronsRight,
  Gem,
  LogOut,
  Mail,
  MessagesSquare,
  Search,
  Settings,
  ShoppingCart,
  User2,
} from "lucide-react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

//import images
import userProfile from "assets/images/users/user-profile.png";
import logoSm from "assets/images/logo-sm.png";
import logoDark from "assets/images/logo-dark.png";
import logoLight from "assets/images/logo-light.png";

//import components
// import LanguageDropdown from 'Common/LanguageDropdown';
import LightDark from "Common/LightDark";
import NotificationDropdown from "Common/NotificationDropdown";
import { Dropdown } from "Common/Components/Dropdown";
import { changeLeftsidebarSizeType } from "slices/thunk";
import {
  changeLeftSidebarSizeType,
  createLayoutSelector,
  useLayoutDispatch,
} from "slices/layout";
import BasicButton from "Common/Components/BasicButton";
import Modal from "Common/Components/Modal";
import { logout, useAuthDispatch } from "slices/authv2";

const Header = ({ handleToggleDrawer, handleDrawer }: any) => {
  const dispatch = useDispatch<any>();
  const layoutDispatch = useLayoutDispatch();
  const authDispatch = useAuthDispatch();

  const handleLogout = () => {
    authDispatch(logout());
  };
  // react-redux
  // const selectLayoutState = (state: any) => state.Layout;
  const selectLayoutProperties = createLayoutSelector(
    "layoutSidebarSizeType",
    "layoutType"
  );

  const { layoutSidebarSizeType, layoutType } = useSelector(
    selectLayoutProperties
  );

  const handleTopbarHamburgerIcon = () => {
    var windowSize = document.documentElement.clientWidth;
    let sidebarOverlay = document.getElementById("sidebar-overlay") as any;

    if (windowSize < 768) {
      // document.body.classList.add("overflow-hidden");
      if (sidebarOverlay.classList.contains("hidden")) {
        sidebarOverlay.classList.remove("hidden");
        (document as Document | any)
          .querySelector(".app-menu")
          .classList.remove("hidden");
      } else {
        sidebarOverlay.classList.add("hidden");
        (document as Document | any)
          .querySelector(".app-menu")
          .classList.add("hidden");
      }
      layoutDispatch(changeLeftSidebarSizeType("lg"));
    } else {
      layoutDispatch(
        changeLeftSidebarSizeType(layoutSidebarSizeType === "sm" ? "lg" : "sm")
      );
    }
  };

  const [showModal, setShowModal] = useState<boolean>(false);
  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleHideModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    // resize
    const handleResizeLayout = () => {
      var windowSize = document.documentElement.clientWidth;

      if (windowSize < 768) {
        layoutDispatch(changeLeftSidebarSizeType("lg"));
      } else if (windowSize <= 1024) {
        if (layoutType === "vertical") {
          layoutDispatch(changeLeftSidebarSizeType("sm"));
        } else {
          layoutDispatch(changeLeftSidebarSizeType("lg"));
        }
      } else {
        layoutDispatch(changeLeftSidebarSizeType("lg"));

        // layoutDispatch(changeLeftSidebarSizeType(layoutSidebarSizeType === "sm" ? "lg" : "sm"));
      }
    };

    // out side click
    const outerSideElement = () => {
      var windowSize = document.documentElement.clientWidth;
      var sidebarOverlay = document.getElementById("sidebar-overlay") as any;
      if (sidebarOverlay) {
        sidebarOverlay.addEventListener("click", () => {
          if (!sidebarOverlay.classList.contains("hidden")) {
            if (windowSize <= 768) {
              document?.querySelector(".app-menu")?.classList.add("hidden");
              // document.body.classList.remove("overflow-hidden");
              sidebarOverlay.classList.add("hidden");
            } else {
              layoutDispatch(changeLeftSidebarSizeType("lg"));
            }
          }
        });
      }
    };

    // scroll
    const scrollNavigation = () => {
      var scrollUp = document.documentElement.scrollTop;
      if (scrollUp >= 50) {
        document.getElementById("page-topbar")?.classList.add("is-sticky");
      } else {
        document.getElementById("page-topbar")?.classList.remove("is-sticky");
      }
    };

    window.addEventListener("scroll", scrollNavigation, true);
    window.addEventListener("click", outerSideElement);
    window.addEventListener("resize", handleResizeLayout);

    // Cleanup function to remove the event listeners
    return () => {
      window.removeEventListener("scroll", scrollNavigation, true);
      window.removeEventListener("click", outerSideElement);
      window.removeEventListener("resize", handleResizeLayout);
    };
  }, [layoutType, dispatch, layoutDispatch]);

  return (
    <React.Fragment>
      <header
        id="page-topbar"
        className="ltr:md:left-vertical-menu rtl:md:right-vertical-menu group-data-[sidebar-size=md]:ltr:md:left-vertical-menu-md group-data-[sidebar-size=md]:rtl:md:right-vertical-menu-md group-data-[sidebar-size=sm]:ltr:md:left-vertical-menu-sm group-data-[sidebar-size=sm]:rtl:md:right-vertical-menu-sm group-data-[layout=horizontal]:ltr:left-0 group-data-[layout=horizontal]:rtl:right-0 fixed right-0 z-[1000] left-0 print:hidden group-data-[navbar=bordered]:m-4 group-data-[navbar=bordered]:[&.is-sticky]:mt-0 transition-all ease-linear duration-300 group-data-[navbar=hidden]:hidden group-data-[navbar=scroll]:absolute group/topbar group-data-[layout=horizontal]:z-[1004]"
      >
        <div className="layout-width">
          <div className="flex bg-[#191919] items-center px-4 mx-auto  border-b-2 border-topbar group-data-[topbar=dark]:bg-topbar-dark group-data-[topbar=dark]:border-topbar-dark group-data-[topbar=brand]:bg-topbar-brand group-data-[topbar=brand]:border-topbar-brand shadow-md h-header shadow-slate-200/50 group-data-[navbar=bordered]:rounded-md group-data-[navbar=bordered]:group-[.is-sticky]/topbar:rounded-t-none group-data-[topbar=dark]:dark:bg-zink-700 group-data-[topbar=dark]:dark:border-zink-700 dark:shadow-none group-data-[topbar=dark]:group-[.is-sticky]/topbar:dark:shadow-zink-500 group-data-[topbar=dark]:group-[.is-sticky]/topbar:dark:shadow-md group-data-[navbar=bordered]:shadow-none group-data-[layout=horizontal]:group-data-[navbar=bordered]:rounded-b-none group-data-[layout=horizontal]:shadow-none group-data-[layout=horizontal]:dark:group-[.is-sticky]/topbar:shadow-none">
            <div className="flex items-center w-full group-data-[layout=horizontal]:mx-auto group-data-[layout=horizontal]:max-w-screen-2xl navbar-header group-data-[layout=horizontal]:ltr:xl:pr-3 group-data-[layout=horizontal]:rtl:xl:pl-3">
              <div className="items-center justify-center hidden px-5 text-center h-header group-data-[layout=horizontal]:md:flex group-data-[layout=horizontal]:ltr::pl-0 group-data-[layout=horizontal]:rtl:pr-0">
                <Link to="/">
                  <span className="hidden">
                    <img
                      src="https://collegepass.s3.ap-south-1.amazonaws.com/header-icon.png"
                      alt=""
                      className="h-6 mx-auto"
                    />
                  </span>
                  <span className="group-data-[topbar=dark]:hidden group-data-[topbar=brand]:hidden">
                    <img
                      src="https://collegepass.s3.ap-south-1.amazonaws.com/header-icon.png"
                      alt=""
                      className="h-6 mx-auto"
                    />
                  </span>
                </Link>
                <Link
                  to="/"
                  className="hidden group-data-[topbar=dark]:block group-data-[topbar=brand]:block"
                >
                  <span className="group-data-[topbar=dark]:hidden group-data-[topbar=brand]:hidden">
                    <img
                      src="https://collegepass.s3.ap-south-1.amazonaws.com/header-icon.png"
                      alt=""
                      className="h-6 mx-auto"
                    />
                  </span>
                  <span className="group-data-[topbar=dark]:block group-data-[topbar=brand]:block">
                    <img
                      src="https://collegepass.s3.ap-south-1.amazonaws.com/header-icon.png"
                      alt=""
                      className="h-6 mx-auto"
                    />
                  </span>
                </Link>
              </div>

              <button
                onClick={handleTopbarHamburgerIcon}
                type="button"
                className="inline-flex bg-[#191919] relative justify-center items-center p-0 text-topbar-item transition-all size-[37.5px] duration-75 ease-linear rounded-md btn hover:bg-[#ffffff00] group-data-[topbar=dark]:bg-topbar-dark group-data-[topbar=dark]:border-topbar-dark group-data-[topbar=dark]:text-topbar-item-dark group-data-[topbar=dark]:hover:bg-topbar-item-bg-hover-dark group-data-[topbar=dark]:hover:text-topbar-item-hover-dark group-data-[topbar=brand]:bg-topbar-brand group-data-[topbar=brand]:border-topbar-brand group-data-[topbar=brand]:text-topbar-item-brand group-data-[topbar=brand]:hover:bg-topbar-item-bg-hover-brand group-data-[topbar=brand]:hover:text-topbar-item-hover-brand group-data-[topbar=dark]:dark:bg-zink-700 group-data-[topbar=dark]:dark:text-zink-200 group-data-[topbar=dark]:dark:border-zink-700 group-data-[topbar=dark]:dark:hover:bg-zink-600 group-data-[topbar=dark]:dark:hover:text-zink-50 group-data-[layout=horizontal]:flex group-data-[layout=horizontal]:md:hidden hamburger-icon bg"
                id="topnav-hamburger-icon"
              >
                {/* <ChevronsLeft className="w-5 h-5 text-white group-data-[sidebar-size=sm]:hidden" /> */}
                {/* <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="far"
                  data-icon="arrow-left-to-line"
                  className="svg-inline--fa fa-arrow-left-to-line fa-fw fa-1x group-data-[sidebar-size=sm]:hidden text-[14px]"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M0 424c0 13.3 10.7 24 24 24s24-10.7 24-24L48 88c0-13.3-10.7-24-24-24S0 74.7 0 88L0 424zM135.6 238.5c-4.8 4.5-7.6 10.9-7.6 17.5s2.7 12.9 7.6 17.5l136 128c9.7 9.1 24.8 8.6 33.9-1s8.6-24.8-1-33.9L212.5 280l83.5 0 128 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-128 0-83.5 0 91.9-86.5c9.7-9.1 10.1-24.3 1-33.9s-24.3-10.1-33.9-1l-136 128z"
                  ></path>
                </svg> */}
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="far"
                  data-icon="arrow-right-from-line"
                  className="svg-inline--fa fa-arrow-right-from-line fa-fw fa-lg hidden group-data-[sidebar-size=sm]:block text-[10px] text-[#cccccc]"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M48 88c0-13.3-10.7-24-24-24S0 74.7 0 88L0 424c0 13.3 10.7 24 24 24s24-10.7 24-24L48 88zM440.4 273.5c4.8-4.5 7.6-10.9 7.6-17.5s-2.7-12.9-7.6-17.5l-136-128c-9.7-9.1-24.8-8.6-33.9 1s-8.6 24.8 1 33.9L363.5 232 280 232l-128 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l128 0 83.5 0-91.9 86.5c-9.7 9.1-10.1 24.3-1 33.9s24.3 10.1 33.9 1l136-128z"
                  ></path>
                </svg>
                {/* <ChevronsRight className="hidden w-5 h-5 text-white group-data-[sidebar-size=sm]:block" /> */}
              </button>

              <div className="relative hidden ltr:ml-3 rtl:mr-3 lg:block group-data-[layout=horizontal]:hidden group-data-[layout=horizontal]:lg:block">
                {/* <input
                  type="text"
                  className="py-2 pr-4 text-sm text-topbar-item bg-topbar border border-topbar-border rounded pl-8 placeholder:text-slate-400 form-control focus-visible:outline-0 min-w-[300px] focus:border-blue-400 group-data-[topbar=dark]:bg-topbar-dark group-data-[topbar=dark]:border-topbar-border-dark group-data-[topbar=dark]:placeholder:text-slate-500 group-data-[topbar=dark]:text-topbar-item-dark group-data-[topbar=brand]:bg-topbar-brand group-data-[topbar=brand]:border-topbar-border-brand group-data-[topbar=brand]:placeholder:text-blue-300 group-data-[topbar=brand]:text-topbar-item-brand group-data-[topbar=dark]:dark:bg-zink-700 group-data-[topbar=dark]:dark:border-zink-500 group-data-[topbar=dark]:dark:text-zink-100"
                  placeholder="Search for ..."
                  autoComplete="off"
                />
                <Search className="inline-block size-4 absolute left-2.5 top-2.5 text-topbar-item fill-slate-100 group-data-[topbar=dark]:fill-topbar-item-bg-hover-dark group-data-[topbar=dark]:text-topbar-item-dark group-data-[topbar=brand]:fill-topbar-item-bg-hover-brand group-data-[topbar=brand]:text-topbar-item-brand group-data-[topbar=dark]:dark:text-zink-200 group-data-[topbar=dark]:dark:fill-zink-600" /> */}
                <ul
                  id="navbar7"
                  className="absolute inset-x-0 z-20 items-center hidden py-3 bg-white shadow-lg dark:bg-zink-600 dark:md:bg-transparent md:z-0 navbar-menu rounded-b-md md:shadow-none md:flex top-full ltr:ml-auto rtl:mr-auto md:relative md:bg-transparent md:rounded-none md:top-auto md:py-0"
                >
                  <li>
                    <a
                      href="#!"
                      className="text-[#e8e8e8] block md:inline-block px-4 md:px-3 py-2.5 md:py-0.5 text-[11px] tracking-[0.05rem] font-normal transition-all duration-300 ease-linear hover:text-[#ffffff] [&.active]:text-[#ffffff]"
                      // onClick={() => handleLinkClick('/Home')}
                    >
                      ADMISSIONS ADVISING
                    </a>
                  </li>
                  <li>
                    <a
                      href="#!"
                      className="text-[#e8e8e8] block md:inline-block px-4 md:px-3 py-2.5 md:py-0.5 text-[11px] tracking-[0.05rem] font-normal transition-all duration-300 ease-linear hover:text-[#ffffff] [&.active]:text-[#ffffff]"
                      // onClick={() => handleLinkClick('/About')}
                    >
                      TEST PREP
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.collegepass.org/blog"
                      className="text-[#e8e8e8] block md:inline-block px-4 md:px-3 py-2.5 md:py-0.5 text-[11px] tracking-[0.05rem] font-normal transition-all duration-300 ease-linear hover:text-[#ffffff] [&.active]:text-[#ffffff]"
                      // onClick={() => handleLinkClick('/Service')}
                    >
                      BLOG
                    </a>
                  </li>
                </ul>
              </div>

              <div className="flex gap-3 ms-auto">
                {/* LanguageDropdown */}
                {/* <LanguageDropdown /> */}

                {/* LightDark */}
                {/* <LightDark /> */}

                {/* AddToCartDrawer */}
                {/* <div className="relative flex items-center h-header">
                                    <button type="button" data-drawer-target="cartSidePenal" className="inline-flex relative justify-center items-center p-0 text-topbar-item transition-all size-[37.5px] duration-200 ease-linear bg-topbar rounded-md btn hover:bg-topbar-item-bg-hover hover:text-topbar-item-hover group-data-[topbar=dark]:bg-topbar-dark group-data-[topbar=dark]:hover:bg-topbar-item-bg-hover-dark group-data-[topbar=dark]:hover:text-topbar-item-hover-dark group-data-[topbar=brand]:bg-topbar-brand group-data-[topbar=brand]:hover:bg-topbar-item-bg-hover-brand group-data-[topbar=brand]:hover:text-topbar-item-hover-brand group-data-[topbar=dark]:dark:bg-zink-700 group-data-[topbar=dark]:dark:hover:bg-zink-600 group-data-[topbar=brand]:text-topbar-item-brand group-data-[topbar=dark]:dark:hover:text-zink-50 group-data-[topbar=dark]:dark:text-zink-200 group-data-[topbar=dark]:text-topbar-item-dark" onClick={handleDrawer}>
                                        <ShoppingCart className="inline-block size-5 stroke-1 fill-slate-100 group-data-[topbar=dark]:fill-topbar-item-bg-hover-dark group-data-[topbar=brand]:fill-topbar-item-bg-hover-brand"></ShoppingCart>
                                        <span className="absolute flex items-center justify-center w-[16px] h-[16px] text-xs text-white bg-red-400 border-white rounded-full -top-1 -right-1">3</span>
                                    </button>
                                </div> */}

                {/* NotificationDropdown */}
                {/* <NotificationDropdown /> */}

                {/* <div className="relative items-center hidden h-header md:flex">
                  <button
                    onClick={handleToggleDrawer}
                    data-drawer-target="customizerButton"
                    type="button"
                    className="inline-flex justify-center items-center p-0 text-topbar-item transition-all size-[37.5px] duration-200 ease-linear bg-topbar group-data-[topbar=dark]:text-topbar-item-dark rounded-md btn hover:bg-topbar-item-bg-hover hover:text-topbar-item-hover group-data-[topbar=dark]:bg-topbar-dark group-data-[topbar=dark]:hover:bg-topbar-item-bg-hover-dark group-data-[topbar=dark]:hover:text-topbar-item-hover-dark group-data-[topbar=brand]:bg-topbar-brand group-data-[topbar=brand]:hover:bg-topbar-item-bg-hover-brand group-data-[topbar=brand]:hover:text-topbar-item-hover-brand group-data-[topbar=dark]:dark:bg-zink-700 group-data-[topbar=dark]:dark:hover:bg-zink-600 group-data-[topbar=brand]:text-topbar-item-brand group-data-[topbar=dark]:dark:hover:text-zink-50 group-data-[topbar=dark]:dark:text-zink-200"
                  >
                    <Settings className="inline-block size-5 stroke-1 fill-slate-100 group-data-[topbar=dark]:fill-topbar-item-bg-hover-dark group-data-[topbar=brand]:fill-topbar-item-bg-hover-brand"></Settings>
                  </button>
                </div> */}

                <Dropdown className="relative flex items-center h-header">
                  <Dropdown.Trigger
                    type="button"
                    className="inline-block p-0 transition-all duration-200 ease-linear"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                  >
                    <div className="rounded-full">
                      <img
                        src="https://collegepass.s3.ap-south-1.amazonaws.com/header-icon.png"
                        alt=""
                        className="h-7 rounded-full"
                      />
                    </div>
                  </Dropdown.Trigger>
                  <Dropdown.Content
                    placement="right-end"
                    className="absolute z-50 p-4 ltr:text-left rtl:text-right bg-white rounded-md shadow-md !top-4 dropdown-menu min-w-[14rem] dark:bg-zink-600"
                    aria-labelledby="dropdownMenuButton"
                  >
                    {/* <h6 className="mb-2 text-sm font-normal text-slate-500 dark:text-zink-300">
                      Welcome to CollegePass
                    </h6>
                    <a href="#!" className="flex gap-3 mb-3">
                      <div className="relative inline-block shrink-0">
                        <div className="rounded bg-slate-100 dark:bg-zink-500">
                          <img
                            src={userProfile}
                            alt=""
                            className="size-12 rounded"
                          />
                        </div>
                        <span className="-top-1 ltr:-right-1 rtl:-left-1 absolute size-2.5 bg-green-400 border-2 border-white rounded-full dark:border-zink-600"></span>
                      </div>
                      <div>
                        <h6 className="mb-1 text-15">
                          Yuvraj
                        </h6>
                        <p className="text-slate-500 dark:text-zink-300">
                          Student
                        </p>
                      </div>
                    </a> */}
                    <ul>
                      <li>
                        <a
                          className="block ltr:pr-4 rtl:pl-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:text-custom-500 focus:text-custom-500 dark:text-zink-200 dark:hover:text-custom-500 dark:focus:text-custom-500"
                          href="https://advisor.collegepass.org/"
                        >
                          <User2 className="inline-block size-4 ltr:mr-2 rtl:ml-2"></User2>{" "}
                          Profile Builder
                        </a>
                      </li>
                      <li>
                        <a
                          className="block ltr:pr-4 rtl:pl-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:text-custom-500 focus:text-custom-500 dark:text-zink-200 dark:hover:text-custom-500 dark:focus:text-custom-500"
                          href="https://www.collegepass.org/account"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="lucide lucide-badge-info inline-block size-4 ltr:mr-2 rtl:ml-2"
                          >
                            <path d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z" />
                            <line x1="12" x2="12" y1="16" y2="12" />
                            <line x1="12" x2="12.01" y1="8" y2="8" />
                          </svg>
                          {/* <Mail className="inline-block size-4 ltr:mr-2 rtl:ml-2"></Mail>{" "} */}
                          My Account{" "}
                          {/* <span className="inline-flex items-center justify-center size-5 ltr:ml-2 rtl:mr-2 text-[11px] font-medium border rounded-full text-white bg-red-500 border-red-500">
                            15
                          </span> */}
                        </a>
                      </li>
                      {/* <li>
                        <a
                          className="block ltr:pr-4 rtl:pl-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:text-custom-500 focus:text-custom-500 dark:text-zink-200 dark:hover:text-custom-500 dark:focus:text-custom-500"
                          href={process.env.PUBLIC_URL + "/apps-chat"}
                        >
                          <MessagesSquare className="inline-block size-4 ltr:mr-2 rtl:ml-2"></MessagesSquare>{" "}
                          AI Assistant
                        </a>
                      </li> */}
                      {/* <li>
                        <a
                          className="block ltr:pr-4 rtl:pl-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:text-custom-500 focus:text-custom-500 dark:text-zink-200 dark:hover:text-custom-500 dark:focus:text-custom-500"
                          href={process.env.PUBLIC_URL + "/pages-pricing"}
                        >
                          <Gem className="inline-block size-4 ltr:mr-2 rtl:ml-2"></Gem>{" "}
                          Upgrade{" "}
                          <span className="inline-flex items-center justify-center w-auto h-5 ltr:ml-2 rtl:mr-2 px-1 text-[12px] font-medium border rounded text-white bg-sky-500 border-sky-500">
                            Pro
                          </span>
                        </a>
                      </li> */}
                      <li className="pt-2 mt-2 border-t border-slate-200 dark:border-zink-500">
                        <a
                          className="block ltr:pr-4 rtl:pl-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:text-custom-500 focus:text-custom-500 dark:text-zink-200 dark:hover:text-custom-500 dark:focus:text-custom-500"
                          // href={process.env.PUBLIC_URL + "/logout"}
                          onClick={() => setShowModal(true)}
                        >
                          <LogOut className="inline-block size-4 ltr:mr-2 rtl:ml-2"></LogOut>{" "}
                          Sign Out
                        </a>
                      </li>
                    </ul>
                  </Dropdown.Content>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </header>

      <Modal
        show={showModal}
        onHide={() => handleHideModal()}
        id="topModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600 flex flex-col"
      >
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4">
          <div className="text-center">
            <p className="text-[18px]">Are you sure you want to logout?</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="mb-4 mt-4 text-center flex justify-center">
            <BasicButton
              data-modal-target="addNotesModal"
              type="button"
              className="mr-2 text-white btn bg-[#dc3545] border-[#dc3545] hover:text-white hover:bg-[#bd2130] hover:border-[#bd2130] focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              // onClick={toggle}
              // onClick={handleSubmit(onSubmit)}
              // disabled={isSubmitting}
              onClick={() => handleLogout()}
            >
              <span className="align-middle">Logout</span>
            </BasicButton>
            <BasicButton
              data-modal-target="addNotesModal"
              customColor="red"
              className="bg-red-100 !text-red-500 hover:!text-white hover:bg-red-600 focus:text-white focus:bg-red-600 focus:ring focus:ring-red-100 active:text-white active:bg-red-600 active:ring active:ring-red-100"
              onClick={() => setShowModal(false)}
              // disabled={isSubmitting}
            >
              <span className="align-middle">Cancel</span>
            </BasicButton>
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default Header;
