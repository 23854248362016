import React, { type ComponentPropsWithoutRef } from "react";
import { type UseFormRegisterReturn } from "react-hook-form";

type CheckBoxProps = ComponentPropsWithoutRef<"button"> & {
  label?: string;
  labelClassName?: string;
  required?: boolean;
  color?:
    | "custom"
    | "green"
    | "orange"
    | "sky"
    | "yellow"
    | "red"
    | "purple"
    | "slate";
  customColor?: string; // Allow specifying a custom background color
  shade?: string | number; // Shade to use (default 500)
  register?: UseFormRegisterReturn;
};

export default ({
  id,
  className,
  labelClassName,
  label,
  register,
  required = false,
  color = "custom",
  customColor,
  shade = 500,
  ...otherProps
}: CheckBoxProps) => {
  // Define classes for primary color, hover, focus, active, and dark states
  const baseShade = Number(shade); // Convert shade to a number for dynamic adjustments

  const baseColorClass = customColor
    ? `bg-[${customColor}] border-[${customColor}]`
    : `bg-${color}-${baseShade} border-${color}-${baseShade}`;

  const hoverColorClass = customColor
    ? `hover:bg-[${customColor}] hover:border-[${customColor}]`
    : `hover:bg-${color}-${baseShade + 100} hover:border-${color}-${
        baseShade + 100
      }`;

  const focusColorClass = customColor
    ? `focus:bg-[${customColor}] focus:border-[${customColor}] focus:ring-[${customColor}]`
    : `focus:bg-${color}-${baseShade + 100} focus:border-${color}-${
        baseShade + 100
      } focus:ring-${color}-${baseShade - 400}`;

  const activeColorClass = customColor
    ? `active:bg-[${customColor}] active:border-[${customColor}] active:ring-[${customColor}]`
    : `active:bg-${color}-${baseShade + 100} active:border-${color}-${
        baseShade + 100
      } active:ring-${color}-${baseShade - 400}`;

  const darkRingClass = customColor
    ? `dark:ring-[${customColor}] dark:ring-opacity-20`
    : `dark:ring-${color}-${baseShade - 100}/20`;

  // Combine all classes
  const buttonClass = `text-white btn ${baseColorClass} ${hoverColorClass} ${focusColorClass} ${activeColorClass} ${darkRingClass}`;

  return (
    <React.Fragment>
      <div>
        {label && (
          <label
            htmlFor={id || "inputPlaceholder"}
            className={
              "block mb-2 text-base font-medium" + " " + labelClassName
            }
          >
            {label} {required && <span className="text-red-500">*</span>}
          </label>
        )}
        <button
          id={id}
          type="button"
          className={`${buttonClass} ${className}`}
          {...(register ? register : {})}
          {...otherProps}
        >
          {otherProps.children}
        </button>
      </div>
    </React.Fragment>
  );
};
